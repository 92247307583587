<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :collapseHeight="form.tab !== 'topped' ? 120 : 60"
    :toggleCollapseEnable="form.tab !== 'topped'" :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium" class="filter-from">

      <!--      <el-form-item label="时间：">-->
      <!--        <DatePicker v-model="form.start_time" style="width: auto;" placeholder="请选择日期"-->
      <!--                    type="datetime" format="yyyy-MM-dd HH:mm"></DatePicker>-->
      <!--        <span style="padding: 0 10px">至</span>-->
      <!--        <DatePicker v-model="form.end_time" style="width: auto;" placeholder="请选择日期"-->
      <!--                    type="datetime" format="yyyy-MM-dd HH:mm"></DatePicker>-->
      <!--      </el-form-item>-->
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item" v-if="form.tab != 'topped'">
        <el-form-item label="文章类型：" prop="type">
          <el-select v-model="form.type" placeholder="请选择文章类型">
            <el-option v-for="(option, index) in type_option" :key="index" :label="option.label"
              :value="option.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="作者：" prop="author_name">
          <!--          <el-select v-model="form.nickname" placeholder="请输入作者名称">-->
          <!--            <el-option @click.native="Author(option.user_id,option.user_type)"-->
          <!--              v-for="(option,index) in authorsList" :key="index" :label="option.nickname"-->
          <!--              :value="option.user_id"></el-option>-->
          <!--          </el-select>-->
          <el-input v-model="form.author_name" placeholder="请输入作者名称"></el-input>
        </el-form-item>
        <el-form-item label="标题：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入标题"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="分类：" prop="category_id" v-if="form.tab != 'topped'">
          <!--          <el-select v-model="form.category_id" placeholder="请选择文章类型">-->
          <!--            <el-option label="全部" :value="0"></el-option>-->
          <!--            <el-option v-for="(option,index) in category_option" :key="index" :label="option.name"-->
          <!--              :value="option.id"></el-option>-->
          <!--          </el-select>-->
          <el-cascader v-model="form.category_id" :options="categories" :props="casProps" @change="handleLevelChange"
            ref="levels" :show-all-levels="false" placeholder="请选择分类" clearable>
          </el-cascader>
        </el-form-item>
        <el-form-item label="类型：" prop="article_type">
          <el-select v-model="form.article_type" placeholder="请选择文章类型">
            <el-option v-for="(option, index) in article_type_option" :key="index" :label="option.label"
              :value="option.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="位置：" prop="category_id" v-if="form.tab == 'topped'">
          <el-select v-model="form.category_id" placeholder="请选择文章位置">
            <el-option label="全部" :value="0"></el-option>
            <el-option v-for="(option, index) in category_option" :key="index" :label="option.name"
              :value="option.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：" prop="top_status" v-if="form.tab == 'topped'">
          <el-select v-model="form.top_status" placeholder="请选择置顶状态">
            <el-option v-for="(option, index) in top_type" :key="index" :label="option.label"
              :value="option.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题：" prop="keyword" v-if="form.tab == 'topped'">
          <el-input v-model="form.keyword" placeholder="请输入标题"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item" v-if="form.tab != 'topped'">
        <DatePeriodPicker label="时间：" start-prop="start_time" :start-time.sync="form.start_time" end-prop="end_time"
          :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";

import { allClassifyData } from "../../api/classify-list";
import { documentArticle } from "../../api/article-list";
import { getAllCategories } from "../../api/write-article/article-left";

export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      type_option: [
        { label: "全部", value: -1 },
        { label: "纯文本", value: 0 },
        { label: "图文", value: 1 },
        { label: "大图", value: 2 },
        { label: "视频", value: 3 },
      ],
      top_type: [
        { label: "全部", value: -1 },
        { label: "已失效", value: 0 },
        { label: "激活中", value: 1 },
        { label: "未激活", value: 2 },
      ],
      article_type_option: [{ label: "全部", value: -1 },
      { label: "在小程序、H5 微网站内浏览", value: 0 },
      { label: "跳转至公众号浏览", value: 1 },],
      category_option: [],
      // authorsList: [],
      // 级联属性
      casProps: {
        children: "_children",
        label: "name",
        value: "id",
        expandTrigger: "hover",
        emitPath: false,
        checkStrictly: true,
      },
      // 级联数据
      categories: [],
    };
  },
  watch: {
    "form.category_id"(val) {
      if (val === null) this.form.category_id = 0;
    },
  },
  methods: {
    handleLevelChange(e) {
      // if (this.$refs.levels && this.$refs.levels.getCheckedNodes().length > 0) {
      // let flag = this.$refs.levels.getCheckedNodes()[0].data.isLeaf;
      // if (flag || (!this.$refs.levels.getCheckedNodes()[0].hasChildren)) {
      this.$refs.levels.dropDownVisible = false;
      // }
      // }
    },
    getAllCategories() {
      getAllCategories({})
        .then((res) => {
          this.categories = [...res.data];
        })
        .catch((err) => { });
    },
    Author(e, i) {
      this.form.user_id = e;
      this.form.user_type = i;
    },
    onExport() {
      const postData = { ...this.form };
      return documentArticle(postData)
        .then(([blob, fileName]) => { })
        .catch(() => { });
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    allClassifyData()
      .then((res) => {
        this.category_option = res.data;
      })
      .catch((err) => {
        // if (this.$axios.isCancel(err)) return;
      });
    this.getAllCategories();
    // getAuthorsList()
    //   .then((res) => {
    //     this.authorsList = res.data;
    //   })
    //   .catch((err) => {
    //     if (this.$axios.isCancel(err)) return;
    //   });
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) { },
    },
    pid: {
      get() {
        return this.form.pid == -2 ? "" : this.form.pid;
      },
      set(val) {
        this.form.pid = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
